<template>
  <div class="o_head_main">
    <div class="o_head">
      <div style="margin-left: 300px">
        <img
          style="height: 50px"
          :src="$store.state.user.web_data.web_logo.value"
          alt=""
        />
      </div>
      <div class="nav">
        <div class="goto_nav">
          <div
            @click="goto(item, index)"
            class="nav-items"
            v-for="(item, index) in list"
            :key="index"
          >
            <span :class="{ active: active === index }">{{ item.title }}</span>
          </div>
        </div>

        <div class="user_info">
          <div style="display: flex" v-if="cookie.getCookie('user') === ''">
            <div class="login">
              <a :href="`${baseUrl.ppUrl}/user/login?brand=1`">登录</a>
            </div>
            <div class="register">
              <a :href="`${baseUrl.ppUrl}/user/login?register=1`"> 免费注册 </a>
            </div>
          </div>
          <div v-else>
            <a :href="baseUrl.ppUrl"
              >{{ JSON.parse(cookie.getCookie("user")).nickName }}-{{
                JSON.parse(cookie.getCookie("user")).phone
              }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseUrl from "../utils/baseUrl";
import cookie from "../utils/cookie";
export default {
  name: "Header",
  data() {
    return {
      cookie,
      baseUrl,
      active: 0,
      data: {
        web_logo: {
          value: "",
        },
      },
      list: [
        {
          title: "首页",
          path: "/",
          hot: false,
        },
        {
          title: "专利服务",
          path: "/patentservice",
          hot: false,
        },
        {
          title: "商标服务",
          path: "/trademarkservice",
          hot: false,
        },
        {
          title: "版权服务",
          path: "/copyrightservice",
          hot: false,
        },
        {
          title: "创新服务",
          path: "/innovativeservice",
          hot: false,
        },
        {
          title: "新闻资讯",
          path: "/news",
          hot: false,
        },
      ],
    };
  },
  created() {
    this.active = parseInt(sessionStorage.getItem("active"));
  },
  methods: {
    goto(item, index) {
      this.active = index;
      sessionStorage.setItem("active", index);
      // this.active = sessionStorage.getItem("active");
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang='less'>
.o_head_main {
  background: transparent;
  height: 74px;
  line-height: 74px;
  position: relative;
  .o_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    min-width: 1200px;
    height: 74px;
    line-height: 74px;
    background-color: rgba(0, 0, 0, 0.85);
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .goto_nav {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .nav-items {
          font-size: 14px;
          font-weight: 500;
          transition: all 0.3s ease-out;
          cursor: pointer;
          margin: 0 10px;
          color: #fff;
          &:hover,
          .active {
            color: #f13a3b;
          }
        }
      }
      .user_info {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        margin-left: 50px;
        div {
          > a {
            margin: 0 20px;
            cursor: pointer;
            color: #fff;
            transition: all 0.3s ease-out;
            &:hover {
              color: #f13a3b;
            }
          }
          cursor: pointer;
          transition: all 0.3s ease-out;
          &:hover {
            color: #f13a3b;
          }
        }
      }
    }
  }
}
</style>
