
function getCookie(cookieName) {
    var cookieStr = unescape(document.cookie);
    var arr = cookieStr.split("; ");
    var cookieValue = "";
    for (var i = 0; i < arr.length; i++) {
        var temp = arr[i].split("=");
        if (temp[0] == cookieName) {
            cookieValue = temp[1];
            break;
        }
    }
    return cookieValue;
}

function saveCookie(cookieName, cookieValue) {
    var d = new Date();
    let expiresTime = new Date(d.getTime() + 60 * 60 * 2 * 1000);
    document.cookie =
        cookieName + "=" + cookieValue + ";expires=" + expiresTime;
}

export default {
    getCookie,
    saveCookie
}
