import axios from 'axios'
import defaultSettings from './baseUrl'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: defaultSettings.baseURL,
    timeout: 20000 // 请求超时时间
})
const errorHandler = error => {
    return Promise.reject(error.message) // reject这个错误信息
}

request.interceptors.request.use(config => {
    return config
}, errorHandler)

request.interceptors.response.use(response => {
    return response.data
}, errorHandler)

export default request

