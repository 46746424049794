const defaultState = () => {
    return {
        web_data: {
            web_logo: {
                value: ''
            },
            default_consultant: {
                value: "{\"name\":\"\",\"avatar\":\"\",\"tel\":\"\",\"qrcode\":\"\"}"
            },
            copy_right: {
                value: ''
            },
            web_info: {
                value: "{\"url\": \"\",\"code\":\"\"}"
            },
            web_qrcode: {
                value: ''
            },
            hot_line: {
                value: ''
            },
            // legal_declaration: {
            //     name: '',
            //     value: ''
            // },
            zl_consultant: {},
            sb_consultant: {},
            bq_consultant: {},
            cx_consultant: {},
        },
        guwen: {
            patentlist: {},
            trademark: {},
            copyrightList: {},
            innovativeservice: {}
        },
        api: {}
    }
}

const state = defaultState()

const mutations = {

}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}