const hostname = window.location.hostname

let ppUrl = {
  // 本地
  'localhost': 'http://localhost:8000',
  // 测试
  'test-plat-pp.pizhigu.com': 'https://test-plat.pizhigu.com',
  // 测试环境
  'pp.pizhigu.com': 'https://plat.pizhigu.com',
  // 生产
  'brand.pizhigu.com': 'https://plat.pizhigu.com',
  // demo
  'demo-pp.pizhigu.com': 'https://demo-plat-web.pizhigu.com',
  // 每创
  // 'ip.mcpolicy.com': 'https://plat.mcpolicy.com',
  'www.mcpolicy.com': 'https://plat.mcpolicy.com',
  //
  'brand.aihzw.cn': 'https://plat.aihzw.cn',
  // 大奥
  'brand.daxiaoao.com': 'https://plat.daxiaoao.com',
  // 宏铎
  'brand.hdzscq.net': 'https://plat.hdzscq.net',
}[hostname]


let baseURL = {
  //达奥
  'brand.daxiaoao.com': 'api.daxiaoao.com',
  // 宏铎
  'brand.hdzscq.net': 'api.hdzscq.net',
  // 本地
  'localhost': 'test-plat-api.pizhigu.com',
  // demo
  'demo-pp.pizhigu.com': 'demo-plat-api.pizhigu.com',
  // 测试环境
  'test-plat-pp.pizhigu.com': 'test-plat-api.pizhigu.com',
  // 生产环境
  'brand.pizhigu.com': 'api-platform.pizhigu.com',
  'platform.pizhigu.com': 'api-platform.pizhigu.com',
  'admin-platform.pizhigu.com': 'api-platform.pizhigu.com',
  // 每创
  'plat.mcpolicy.com': 'api.mcpolicy.com',
  // 'ip.mcpolicy.com': 'api.mcpolicy.com',
  'www.mcpolicy.com': 'api.mcpolicy.com',
  // 君周
  'brand.aihzw.cn': 'api.aihzw.cn',
  //
  'admin.aihzw.cn': 'api.aihzw.cn',
} [hostname]
baseURL = 'https://' + baseURL
// -------------
export default {
  baseURL,
  ppUrl,
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true'
}