<template>
  <div id="app">
    <Header />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./comon/Header.vue";
import { customerList, url } from "./api/index";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {};
  },
  created() {
    this.getUrl();
    this.icon();
    this.getPrice();
  },
  methods: {
    ...mapActions("home", ["gPrice"]),
    async getUrl() {
      const params = {
        urlDomain: window.location.hostname,
      };
      const res = await url(params);
      if (res.code === 200) {
        this.$store.state.user.api = res.data;
      }
    },
    async getPrice() {
      const res = await this.gPrice();
      if (res) {
        console.log("价格");
      }
    },
    async icon() {
      const web_data = {
        web_logo: {},
        web_name: {},
        web_qrcode: {},
        enter_name: {},
        copy_right: {},
        pay_info: {},
        tab_icon: {},
        load_banner: {},
        web_info: {},
        hot_line: {},
        reg_info: {},
        default_consultant: {},
        zl_consultant: {},
        sb_consultant: {},
        bq_consultant: {},
        cx_consultant: {},
        legal_declaration: {},
        privacy_terms: {},
        service_time: {},
        enter_address: {},
      };
      const params = {
        keyList: [
          "web_logo",
          "web_name",
          "web_qrcode",
          "enter_name",
          "copy_right",
          "pay_info",
          "tab_icon",
          "load_banner",
          "web_info",
          "hot_line",
          "reg_info",
          "default_consultant",
          "zl_consultant",
          "sb_consultant",
          "bq_consultant",
          "cx_consultant",
          "legal_declaration",
          "privacy_terms",
          "service_time",
          "enter_address",
        ],
      };
      const res = await customerList(params);
      console.log(res.data);
      if (res.code === 200) {
        res.data.filter((item) => {
          for (const key in web_data) {
            if (item.key === key) {
              web_data[key] = item;
            }
          }
        });
        this.$store.state.user.guwen.patentlist = web_data.zl_consultant.value
          ? JSON.parse(web_data.zl_consultant.value)
          : {};
        this.$store.state.user.guwen.trademark = web_data.sb_consultant.value
          ? JSON.parse(web_data.sb_consultant.value)
          : {};
        this.$store.state.user.guwen.copyrightList = web_data.bq_consultant
          .value
          ? JSON.parse(web_data.bq_consultant.value)
          : {};
        this.$store.state.user.guwen.innovativeservice = web_data.cx_consultant
          .value
          ? JSON.parse(web_data.cx_consultant.value)
          : {};
        this.$store.state.user.web_data = web_data;

        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        document.title = web_data.enter_name.value;
        link.rel = "shortcut icon";
        link.href = web_data.tab_icon.value;
        document.getElementsByTagName("head")[0].appendChild(link); //icon图标
      }
    },
  },
};
</script>

<style lang="less">
</style>
