import request from '../utils/axios'
import axios from '../utils/request'

// url
export function url(params) {
    return axios({
        url: '/patent/plat/url',
        method: 'post',
        data: params
    })
}


// 客户列表
export function customerList(params) {
    return request({
        url: '/base/open/detailList',
        method: 'post',
        data: params
    })
}


//  需求
export function needs(params) {
    return request({
        url: '/web/open/needs_create',
        method: 'post',
        data: params
    })
}
// 留言
export function guestbook(params) {
    return request({
        url: '/web/open/guestbook_add',
        method: 'post',
        data: params
    })
}
// 客户列表
export function price(params) {
    return request({
        url: '/web/open/price_brand',
        method: 'post',
        data: params
    })
}

// 新闻列表
export function news(params) {
    return request({
        url: '/web/open/news_list',
        method: 'post',
        data: params
    })
}

// 新闻列表
export function newsColumnList(params) {
    return request({
        url: '/web/open/news_column_list',
        method: 'post',
        data: params
    })
}

// 新闻详情
export function news_detail(params) {
    return request({
        url: '/web/open/news_detail',
        method: 'post',
        data: params
    })
}

// 验证码
export function smsCode(params) {
    return request({
        url: '/base/open/smsCode',
        method: 'post',
        data: params
    })
}

