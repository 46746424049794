import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
import cookie from '../utils/cookie'
import { Base64 } from 'js-base64'
const Home = () => import('../components/Home/Home')
const PatentService = () => import('../components/PatentService/PatentService')
const TrademarkService = () => import('../components/TrademarkService/TrademarkService')
const CopyrightService = () => import('../components/CopyrightService/CopyrightService')
const Innovativeservice = () => import('../components/Innovativeservice/Innovativeservice')
const News = () => import('../components/News/News')
const Details = () => import('../components/Details/Details')

Vue.use(Router);  //Vue全局使用Router

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
    mode: 'history',
    routes: [              //配置路由，这里是个数组
        {                    //每一个链接都是一个对象
            path: '/',         //链接路径
            name: 'home',     //路由名称，
            component: Home   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/patentservice',         //链接路径
            name: 'PatentService',     //路由名称，
            component: PatentService   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/trademarkservice',         //链接路径
            name: 'TrademarkService',     //路由名称，
            component: TrademarkService   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/copyrightservice',         //链接路径
            name: 'CopyrightService',     //路由名称，
            component: CopyrightService   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/innovativeservice',         //链接路径
            name: 'Innovativeservice',     //路由名称，
            component: Innovativeservice   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/news',         //链接路径
            name: 'News',     //路由名称，
            component: News   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/details',         //链接路径
            name: 'Details',     //路由名称，
            component: Details   //对应的组件模板
        },
    ]
});

// 路由拦截
router.beforeEach(async (to, from, next) => {
    // 推出登录指定值
    // if(to.qeury.out){ 清空cookie 然后跳指定页面 }

    if (to.query.user) {
        let user = Base64.decode(to.query.user)
        cookie.saveCookie('user', user)
        next({path: '/'})
    }
    next()
})

export default router;
