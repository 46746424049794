import Vue from 'vue'
import App from './App.vue'
import vueRouter from 'vue-router'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue';
import router from './route/index'
import store from './store'

Vue.use(Antd);
Vue.use(vueRouter)
new Vue({
    render: h => h(App),
    store, 
    router,
    mounted() {
        window.app = this
    }
}).$mount('#app')