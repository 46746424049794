import {
    price
} from "../../api/index"
const defaultState = () => {
    return {
        modalParms: {},
        problemList: {
            // patentlist: [
            //     {
            //         title: "什么是外观设计专利？",
            //         text: "外观设计，是指对产品的形状、图案或者其结合以及色彩与形状、图案的结合所做出的富有美感并适于工业应用的新设计。",
            //     },
            //     {
            //         title: "外观设计和实用新型的不同之处？",
            //         text: "实用新型保护的是产品结构与形状的改进，外观设计保护的是外形、图案、色彩或其结合。",
            //     },
            //     {
            //         title: "可以产品先上市再申请外观吗？",
            //         text: "尽量不要将产品先上市，后申请，这样风险很高。",
            //     },
            //     {
            //         title: "外观设计应当符合哪些要求？",
            //         text: "是指形状、图案或者其结合，色彩与形状、图案结合的设计；必须是对产品的外表所作的设计；必须富有美感；必须是适于工业上的应用。",
            //     },
            //     {
            //         title: "外观设计专利名称有什么要求？",
            //         text: "使用外观设计的产品名称应当与外观设计图片或者照片中表示的外观设计相符合，准确、简明地表明要求保护的产品的外观设计；产品名称一般不得超过10个字。",
            //     },
            //     {
            //         title: "外观设计专利申请文件包括哪些？",
            //         text: "请求书、外观设计图片或照片、外观设计简要说明。",
            //     },
            //     {
            //         title: "将同一产品的多项相似外观设计能否作为一件申请提出？",
            //         text: "可以，但是一件外观设计专利申请中的相似外观设计不得超过10项。",
            //     },
            //     {
            //         title: "同一类别且成套出售或使用的产品的两项以上外观设计能否作为一件申请提出？",
            //         text: "可以，但必须满足各产品属于分类表中同一大类，习惯上同时出售或者同时使用，而且各产品的外观设计具有相同的设计构思。",
            //     },
            //     {
            //         title: "外观设计专利申请文件包括哪些？",
            //         text: "请求书、外观设计图片或照片、外观设计简要说明。",
            //     },
            // ],
            patentlist: {
                '0': [{
                        title: "发明专利申请多长时间可以递交专利局？",
                        text: "材料齐全后，发明5个工作日返初稿，发客户确认，客户确认通过后递交该专利申请。",
                    },
                    {
                        title: "什么是发明提前公开？",
                        text: "申请人请求早日公布其发明专利申请的，应当向国务院专利行政部门声明。国务院专利行政部门对该申请进行初步审查后，除予以驳回的外，应当立即将申请予以公布。递交发明专利默认申请人请求提前公开，这样可以缩短审查周期。",
                    },
                    {
                        title: "发明专利申请文件包括哪些？",
                        text: "请求书、说明书、权利要求书、说明书摘要、附图、摘要附图等。",
                    },
                    {
                        title: "发明的说明书有哪几部分组成？",
                        text: "名称、技术领域、背景技术、发明内容、附图说明、具体实施方式。",
                    },
                    {
                        title: "发明专利名称有什么要求？",
                        text: "名称应当简短、准确地表明发明专利申请要求保护的主题和类型。发明名称中不得含有非技术词语；名称一般不得超过25个字，特殊情况下，可以允许最多到40个字。",
                    },

                    {
                        title: "授予发明和实用新型专利权的实质条件是什么？",
                        text: "<div><p>（1）新颖性：该发明不属于现有技术；也没有任何单位或者个人就同样的发明或者实用新型在申请日以前向专利局提出过申请，并记载在申请日以后公布的专利申请文件或者公告的专利文件中；</p><p>（2）创造性：创造性是是指与现有技术相比，该发明有突出的实质性特点和显著的进步;</p> <p>（3）实用性：实用性是指发明申请的主题必须能够在产业上制造或者使用，并且能够产生积极效果。</p></div>",
                    },
                    {
                        title: "什么是发明创造不丧失新颖性的公开？",
                        text: "<div><p>（1）在中国政府主办或者承认的国际展览会上首次展出的发明创造，在展出之日起6个月内申请专利的，不丧失新颖性。</p><p>（2）在规定的学术会议或者技术会议上首次发表的发明创造，在发表后6个月内申请专利的，不丧失新颖性。</p> <p>（3）他人未经申请人同意而泄露发明创造内容的，申请人于发明创造被泄露之日起6个月内申请专利的，不丧失新颖性。</p></div>",
                    },
                ],
                '1': [{
                        title: "实用新型专利申请多长时间可以递交专利局？",
                        text: "材料齐全后，实用新型10个工作日返初稿，发客户确认，客户确认通过后递交专利专利。",
                    },
                    {
                        title: "实用新型和发明的不同之处？",
                        text: "实用新型只限于保护具有一定形状的产品，不能是一种方法，也不能是没有固定形状的产品。",
                    },
                    {
                        title: "实用新型和外观设计的不同之处？",
                        text: "实用新型保护的是产品结构与形状的改进，外观设计保护的是外形、图案、色彩或其结合。",
                    },
                    {
                        title: "实用新型专利申请文件包括哪些？",
                        text: "请求书、说明书、权利要求书、说明书摘要、附图、摘要附图等。",
                    },
                    {
                        title: "实用新型的说明书有哪几部分组成？",
                        text: "名称、技术领域、背景技术、发明内容、附图说明、具体实施方式。",
                    },
                    {
                        title: "实用新型专利名称有什么要求？",
                        text: "名称应当简短、准确地表明发明专利申请要求保护的主题和类型。发明名称中不得含有非技术词语；名称一般不得超过25个字，特殊情况下，可以允许最多到40个字。",
                    },
                ],
                '2': [{
                        title: "什么是外观设计专利？",
                        text: "外观设计，是指对产品的形状、图案或者其结合以及色彩与形状、图案的结合所做出的富有美感并适于工业应用的新设计。",
                    },
                    {
                        title: "外观设计和实用新型的不同之处？",
                        text: "实用新型保护的是产品结构与形状的改进，外观设计保护的是外形、图案、色彩或其结合。",
                    },
                    {
                        title: "可以产品先上市再申请外观吗？",
                        text: "尽量不要将产品先上市，后申请，这样风险很高。",
                    },
                    {
                        title: "外观设计应当符合哪些要求？",
                        text: "是指形状、图案或者其结合，色彩与形状、图案结合的设计；必须是对产品的外表所作的设计；必须富有美感；必须是适于工业上的应用。",
                    },
                    {
                        title: "外观设计专利名称有什么要求？",
                        text: "使用外观设计的产品名称应当与外观设计图片或者照片中表示的外观设计相符合，准确、简明地表明要求保护的产品的外观设计；产品名称一般不得超过10个字。",
                    },
                    {
                        title: "外观设计专利申请文件包括哪些？",
                        text: "请求书、外观设计图片或照片、外观设计简要说明。",
                    },
                    {
                        title: "将同一产品的多项相似外观设计能否作为一件申请提出？",
                        text: "可以，但是一件外观设计专利申请中的相似外观设计不得超过10项。",
                    },
                    {
                        title: "同一类别且成套出售或使用的产品的两项以上外观设计能否作为一件申请提出？",
                        text: "可以，但必须满足各产品属于分类表中同一大类，习惯上同时出售或者同时使用，而且各产品的外观设计具有相同的设计构思。",
                    },
                    {
                        title: "外观设计专利申请文件包括哪些？",
                        text: "请求书、外观设计图片或照片、外观设计简要说明。",
                    },
                ],
            },
            trademark: {
                '0': [{
                        title: "自助注册、顾问注册、担保注册有什么区别？",
                        text: "<div><p>自助注册（330元）：只需缴纳商标局收取的300元官费及税费等就可在线自助办理商标注册</p><p>顾问注册（620元）：专业代理人推荐类别，查询检索，协助准备材料，代办注册申请流程</p><p>担保注册（1880元）：在顾问注册基础上，如果不能下发商标注册证书，退还全部商标注册费用（包含官费）</p></div>",
                    },
                    {
                        title: "商标注册需要多长时间？",
                        text: "<div><p>整体大约需要12个月左右。</p><p>第一步：形式审查（1个月左右），结束的标志为下发受理通知书</p><p>第二步：实质审查（6个月左右），结束的标志为公告</p><p>第三步：公告（3个月）</p><p>第四步：下证（1个月左右）</p><p>所以整体时间为：1+6+3+2=12个月</p></div>",
                    },
                    {
                        title: "为何申请商标注册时间那么长，能缩短吗？",
                        text: "不能，国家商标局程序规定从申请到发证需要一年左右，任何承诺可缩短注册时间的均为欺骗性质",
                    },
                    {
                        title: "我想注册的商标都已经被注册了，怎么办？",
                        text: "可通过异议申请、撤三、无效宣告或者我方帮助联系持标人通过转让的方式得到",
                    },
                    {
                        title: "怎样增加商标注册成功几率？",
                        text: "申请时将中文、英文、图形等分开申请，通过后可以组合使用，以避免因某一部分近似而被商标局整体驳回的情况",
                    },
                ],
                '1': [{
                        title: "自助注册、顾问注册、担保注册有什么区别？",
                        text: "<div><p>自助注册（330元）：只需缴纳商标局收取的300元官费及税费等就可在线自助办理商标注册</p><p>顾问注册（620元）：专业代理人推荐类别，查询检索，协助准备材料，代办注册申请流程</p><p>担保注册（1880元）：在顾问注册基础上，如果不能下发商标注册证书，退还全部商标注册费用（包含官费）</p></div>",
                    },
                    {
                        title: "商标注册需要多长时间？",
                        text: "<div><p>整体大约需要12个月左右。</p><p>第一步：形式审查（1个月左右），结束的标志为下发受理通知书</p><p>第二步：实质审查（6个月左右），结束的标志为公告</p><p>第三步：公告（3个月）</p><p>第四步：下证（1个月左右）</p><p>所以整体时间为：1+6+3+2=12个月</p></div>",
                    },
                    {
                        title: "为何申请商标注册时间那么长，能缩短吗？",
                        text: "不能，国家商标局程序规定从申请到发证需要一年左右，任何承诺可缩短注册时间的均为欺骗性质",
                    },
                    {
                        title: "我想注册的商标都已经被注册了，怎么办？",
                        text: "可通过异议申请、撤三、无效宣告或者我方帮助联系持标人通过转让的方式得到",
                    },
                    {
                        title: "怎样增加商标注册成功几率？",
                        text: "申请时将中文、英文、图形等分开申请，通过后可以组合使用，以避免因某一部分近似而被商标局整体驳回的情况",
                    },
                ],
                '2': [{
                        title: "自助注册、顾问注册、担保注册有什么区别？",
                        text: "<div><p>自助注册（330元）：只需缴纳商标局收取的300元官费及税费等就可在线自助办理商标注册</p><p>顾问注册（620元）：专业代理人推荐类别，查询检索，协助准备材料，代办注册申请流程</p><p>担保注册（1880元）：在顾问注册基础上，如果不能下发商标注册证书，退还全部商标注册费用（包含官费）</p></div>",
                    },
                    {
                        title: "商标注册需要多长时间？",
                        text: "<div><p>整体大约需要12个月左右。</p><p>第一步：形式审查（1个月左右），结束的标志为下发受理通知书</p><p>第二步：实质审查（6个月左右），结束的标志为公告</p><p>第三步：公告（3个月）</p><p>第四步：下证（1个月左右）</p><p>所以整体时间为：1+6+3+2=12个月</p></div>",
                    },
                    {
                        title: "为何申请商标注册时间那么长，能缩短吗？",
                        text: "不能，国家商标局程序规定从申请到发证需要一年左右，任何承诺可缩短注册时间的均为欺骗性质",
                    },
                    {
                        title: "我想注册的商标都已经被注册了，怎么办？",
                        text: "可通过异议申请、撤三、无效宣告或者我方帮助联系持标人通过转让的方式得到",
                    },
                    {
                        title: "怎样增加商标注册成功几率？",
                        text: "申请时将中文、英文、图形等分开申请，通过后可以组合使用，以避免因某一部分近似而被商标局整体驳回的情况",
                    },
                ]
            },
            copyrightList: {
                '0': [{
                        title: '美术作品指的是什么？',
                        text: '图画、书法作品、产品设计图纸、包装设计等等都可以将其定义为美术作品。立体或者平面作品均属于此项范围。'
                    },
                    {
                        title: '文字作品指的是什么？',
                        text: '文学作品，比如书籍、自传、日记等等都可以申请文字作品版权登记。'
                    },
                    {
                        title: '软件著作权指的是什么？',
                        text: '网站、软件、APP等等使用电子代码实现工作需求的形式。代码可以用来申请软件著作权登记保护。'
                    },
                    {
                        title: '服装样式可以申请美术作品版权吗？',
                        text: '可以将服装设计图纸来申请版权保护。'
                    },
                    {
                        title: '已经注册了商标，还需要申请版权登记吗？',
                        text: '建议您登记版权，图形类的商标、艺术字体类型的商标可以选择版权登记服务。因为版权登记保护以后，可以在所有分类、所有使用场所都获得版权的保护。'
                    },
                    {
                        title: '还有哪些作品可以登记版权？',
                        text: '我国著作权法第三条规定的各类作品均可申请登记。包括：文字作品；口述作品；艺术作品；美术、建筑作品；摄影作品；电影作品和以类似摄制电影的方法创作的作品；设计图、地图、示意图等图形作品和模型作品；计算机软件；法律、行政法规规定的其它作品。'
                    },
                    {
                        title: '版权保护的期限是多久？',
                        text: '版权保护期限，个人自其作品创作完成之日起，至作者死后第50年的12月31日止。法人或者其他组织作品自该作品发表之日起，到发表之日后第50年的12月31日止。作品始终没有发表的，自作品创作完成50年内未发表的，著作权法不再保护。'
                    },
                    {
                        title: '软件著作权给你们代码会不会泄露？',
                        text: '法律有明文规定代理机构不允许泄露用户的注册申请信息，版权合同中包含保密要求。'
                    }
                ],
                '1': [{
                        title: '美术作品指的是什么？',
                        text: '图画、书法作品、产品设计图纸、包装设计等等都可以将其定义为美术作品。立体或者平面作品均属于此项范围。'
                    },
                    {
                        title: '文字作品指的是什么？',
                        text: '文学作品，比如书籍、自传、日记等等都可以申请文字作品版权登记。'
                    },
                    {
                        title: '软件著作权指的是什么？',
                        text: '网站、软件、APP等等使用电子代码实现工作需求的形式。代码可以用来申请软件著作权登记保护。'
                    },
                    {
                        title: '服装样式可以申请美术作品版权吗？',
                        text: '可以将服装设计图纸来申请版权保护。'
                    },
                    {
                        title: '已经注册了商标，还需要申请版权登记吗？',
                        text: '建议您登记版权，图形类的商标、艺术字体类型的商标可以选择版权登记服务。因为版权登记保护以后，可以在所有分类、所有使用场所都获得版权的保护。'
                    },
                    {
                        title: '还有哪些作品可以登记版权？',
                        text: '我国著作权法第三条规定的各类作品均可申请登记。包括：文字作品；口述作品；艺术作品；美术、建筑作品；摄影作品；电影作品和以类似摄制电影的方法创作的作品；设计图、地图、示意图等图形作品和模型作品；计算机软件；法律、行政法规规定的其它作品。'
                    },
                    {
                        title: '版权保护的期限是多久？',
                        text: '版权保护期限，个人自其作品创作完成之日起，至作者死后第50年的12月31日止。法人或者其他组织作品自该作品发表之日起，到发表之日后第50年的12月31日止。作品始终没有发表的，自作品创作完成50年内未发表的，著作权法不再保护。'
                    },
                    {
                        title: '软件著作权给你们代码会不会泄露？',
                        text: '法律有明文规定代理机构不允许泄露用户的注册申请信息，版权合同中包含保密要求。'
                    }
                ],
                '2': [{
                        title: '美术作品指的是什么？',
                        text: '图画、书法作品、产品设计图纸、包装设计等等都可以将其定义为美术作品。立体或者平面作品均属于此项范围。'
                    },
                    {
                        title: '文字作品指的是什么？',
                        text: '文学作品，比如书籍、自传、日记等等都可以申请文字作品版权登记。'
                    },
                    {
                        title: '软件著作权指的是什么？',
                        text: '网站、软件、APP等等使用电子代码实现工作需求的形式。代码可以用来申请软件著作权登记保护。'
                    },
                    {
                        title: '服装样式可以申请美术作品版权吗？',
                        text: '可以将服装设计图纸来申请版权保护。'
                    },
                    {
                        title: '已经注册了商标，还需要申请版权登记吗？',
                        text: '建议您登记版权，图形类的商标、艺术字体类型的商标可以选择版权登记服务。因为版权登记保护以后，可以在所有分类、所有使用场所都获得版权的保护。'
                    },
                    {
                        title: '还有哪些作品可以登记版权？',
                        text: '我国著作权法第三条规定的各类作品均可申请登记。包括：文字作品；口述作品；艺术作品；美术、建筑作品；摄影作品；电影作品和以类似摄制电影的方法创作的作品；设计图、地图、示意图等图形作品和模型作品；计算机软件；法律、行政法规规定的其它作品。'
                    },
                    {
                        title: '版权保护的期限是多久？',
                        text: '版权保护期限，个人自其作品创作完成之日起，至作者死后第50年的12月31日止。法人或者其他组织作品自该作品发表之日起，到发表之日后第50年的12月31日止。作品始终没有发表的，自作品创作完成50年内未发表的，著作权法不再保护。'
                    },
                    {
                        title: '软件著作权给你们代码会不会泄露？',
                        text: '法律有明文规定代理机构不允许泄露用户的注册申请信息，版权合同中包含保密要求。'
                    }
                ],
            },
        },
        copyrightList: {
            serviceList: [{
                    patentTitle: "美术作品版权登记",
                    imgurl: require('../../assets/logo0.png'),
                    title: "美术作品",
                    text: "绘画、书法、雕塑等艺术作品，均可通过美术作品著作权予以保护。",
                    price: '',
                    category: ''
                },
                {
                    patentTitle: "文字作品版权登记",
                    imgurl: require('../../assets/logo1.png'),
                    title: "文字作品版",
                    text: "小说、诗词、散文、论文等以文字形式表现的作品，可通过文字作品著作权保护。",
                    price: '',
                    category: ''
                },
                {
                    patentTitle: "计算机软件著作权登记",
                    imgurl: require('../../assets/logo2.png'),
                    title: "计算机软件",
                    text: "高新企业认定的必要条件。",
                    price: '',
                    category: ''
                },
            ],
            lawList: [{
                    imgurl: require('../../assets/bqqt-logo1.png'),
                    title: "著作权转让",
                    text: "需转让他人使用的应申请变更。",
                    money: '',
                    category: ''
                },
                {
                    title: "更改登记信息",
                    text: "需更改登记信息应申请变更。",
                    imgurl: require('../../assets/bqqt-logo2.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "著作权补证",
                    text: "申请后1-2个工作日完成审核并发证。",
                    imgurl: require('../../assets/bqqt-logo3.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "著作权撤销",
                    text: "申请后1-2个月可以完成撤销。",
                    imgurl: require('../../assets/bqqt-logo4.png'),
                    money: '',
                    category: ''
                },
            ]
        },
        patentlist: {
            serviceList: [{
                    patentTitle: "发明专利申请",
                    title: '发明专利',
                    patentText: "保护对产品、方法或者其他所提出新的技术方 案，保护期限20年。",
                    contentText1: "新产品、新材料、新物质等的技术方案",
                    contentText2: "可以是一个独立、完整的产品",
                    contentText3: "也可以是一个设备或仪器中的零部件",
                    price: '',
                    bg: "#E5EAF3",
                    color: "#0A4B64",
                    category: ''
                },
                {
                    patentTitle: "实用新型专利申请",
                    title: '实用新型',
                    patentText: "保护对产品的形状、构造或者其结合所提出的技术方案，保护期10年。",
                    contentText1: "实用新型只保护产品的形状、构造",
                    contentText2: "实用新型没有实质审查程序",
                    contentText3: "实用新型的费用低",
                    price: '',
                    bg: "#F4D2A4",
                    color: "#7E502A",
                    category: ''
                },
                {
                    patentTitle: "外观专利申请",
                    title: '外观设计',
                    patentText: "保护对产品形状、图案或其结合以及色彩与形状、图案结合所做的新设计，保护期10年。",
                    contentText1: "必须是对产品的外表所作的",
                    contentText2: "设计 必须是适于工业上的应",
                    contentText3: "用 必须富有美感",
                    price: '',
                    bg: "#221613",
                    color: "#E4C8A9",
                    category: ''
                },
            ],
            copyrightList: [{
                    title: "PCT国际申请",
                    text: "一份申请，多国享有优先权。",
                    imgurl: require('../../assets/jz-logo1.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "单个国家直接申请",
                    text: "单一国家申请专利是指中国申请人直接到国外的一个国家或一个地区申请专利。",
                    imgurl: require('../../assets/jz-logo2.png'),
                    money: '',
                    category: ''
                },
            ],
            lawList: [{
                    title: "专利申请复审",
                    text: "专利申请人对国务院专利行政部门驳回申请的决定不服的，可以自收到通知之日起三个月内，向专利复审委员会请求复审。",
                    imgurl: require('../../assets/fw-logo1.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "专利权恢复",
                    text: "专利权终止后2个月内，可以向国务院专利行政部门请求恢复权利。",
                    imgurl: require('../../assets/fw-logo2.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "专利无效宣告",
                    text: "允许对他人的专利不符合专利法规定条件的发明创造专利权提出无效宣告请求。",
                    imgurl: require('../../assets/fw-logo3.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "专利无效答辩",
                    text: "专利遭遇他人提出无效宣告请求时需要进行。",
                    imgurl: require('../../assets/fw-logo4.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "审查意见答复",
                    text: "对国知局下发的审查意见通知书进行规范答复。",
                    imgurl: require('../../assets/fw-logo5.png'),
                    money: '',
                    category: ''
                },
            ],
            otherService: [{
                    title: "著录项目变更",
                    text: "专利信息变更，交易转让。",
                    imgurl: require('../../assets/qt-logo1.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "专利实施许可备案",
                    text: "授权他人，有备无患。",
                    imgurl: require('../../assets/qt-logo2.png'),
                    money: '',
                    category: ''
                },
                {
                    title: "专利价值评估",
                    text: "多维度专利价值度分析。",
                    money: '',
                    imgurl: require('../../assets/qt-logo3.png'),
                    category: ''
                },
                {
                    title: "专利挖掘分析",
                    text: "全方位深度挖掘创新点和发明点。",
                    imgurl: require('../../assets/qt-logo4.png'),
                    money: '',
                    category: ''
                },
            ]
        },
        trademark: {
            serviceList: [{
                    patentTitle: "商标自助注册",
                    title: "商标自助",
                    patentText: "便捷易用，简单高效。递交迅速，反馈及时。全流程掌控，随时查看进度。",
                    contentText1: "提供免费商标查询提高注册成功率",
                    contentText2: "OCR技术智能识别证件",
                    contentText3: "上传资料自动填写",
                    price: '',
                    bg: "#E5EAF3",
                    color: "#0A4B64",
                    category: ''
                },
                {
                    patentTitle: "商标顾问注册",
                    title: "商标顾问",
                    patentText: "新用户省心推荐，专业顾问一对一服务，专注 商标注册代办、商标注册加急等业务，省时更省心",
                    contentText1: "资深专家全流程1对1服务",
                    contentText2: "规避近似风险，提高成功率",
                    contentText3: "综合评估分析，优化注册方案",
                    price: '',
                    bg: "#F4D2A4",
                    color: "#7E502A",
                    category: ''
                },
                {
                    patentTitle: "商标担保注册",
                    title: "商标担保",
                    patentText: "有担保，更放心！规避盲查、主观判断等注册 风险注册失败全额退款！",
                    contentText1: "专业人员精准评估，降低风险",
                    contentText2: "规避盲查，主管判断等注册风险",
                    contentText3: "层层把控，实审失败可退款",
                    price: '',
                    bg: "#221613",
                    color: "#E4C8A9",
                    category: ''
                },
            ],
            renewList: [{
                    title: "商标变更",
                    text: "若商标申请人名称、地址等信息发生变更，应及时向商标局递交变更申请。",
                    money: "",
                    category: ''
                },
                {
                    title: "商标续展",
                    text: "注册商标10年有效期满后需要继续使用的，应当在期满前的12个月内申请续展。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标宽展",
                    text: "商标专用权期满6个月内为宽展期，宽展期内仍未提出申请的，商标局将注销其注册商标。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标转让",
                    text: "商标注册人在注册商标的有效期内，依法定程序，将商标专用权转让给另一方的行为。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标许可备案",
                    text: "通过许可使用盘活持有商标资产，除了签约许可合同外，官方备案不可少！",
                    money: '',
                    category: ''
                },
                {
                    title: "商标更正",
                    text: "商标注册申请人或注册人发现商标申请文件或者注册文件有明显错误的，可以申请更正。",
                    money: '',
                    category: ''
                },
            ],
            registerList: [{
                    title: "商标驳回复审",
                    text: "商标申请人不服商标局驳回的，可以向商评委申请复审。",
                    money: "",
                    category: ''
                },
                {
                    title: "商标异议答辩",
                    text: "处于初审公告期内的商标被他人提起异议，应积极组织材料答辩。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标撤三答辩",
                    text: "商标被他人以注册满三年未使用为由提出撤三申请的，应积极组织材料答辩。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标无效答辩",
                    text: "商标被他人提出无效宣告请求的，应积极组织材料答辩。",
                    money: '',
                    category: ''
                }
            ],
            maintainList: [{
                    title: "商标撤三申请",
                    text: "对他人注册已满三年但未使用的商标，可申请撤销，为自己的商标注册扫清障碍。",
                    money: "",
                    category: ''
                },
                {
                    title: "商标异议申请",
                    text: "对初审公告期内他人审查通过的商标提出异议，维护自身合法权益。",
                    money: '',
                    category: ''
                },
                {
                    title: "商标无效宣告申请",
                    text: "对于他人已核准注册的商标，因侵犯自身合法权益或违反《商标法》相关规定可提起无效宣告请求",
                    money: '',
                    category: ''
                }
            ]
        },
        innovationList: {
            keyan: [{
                title: "科技成果评价",
                text: "国家科技成果认定，如国家科技成果库",
                money: "",
                category: ''
            }, ],
            patentServiceList: [{
                    title: "贯标申请服务",
                    text: "规范企业知识产权管理。",
                    money: "",
                    category: ''
                },
                {
                    title: "知识产权保护",
                    text: "提供全面的知识产权保护方案。",
                    money: "",
                    category: ''
                },
                {
                    title: "网络侵权处理",
                    text: "对互联网中的知识产权侵权进行处理。",
                    money: "",
                    category: ''
                },
                {
                    title: "专项风险预警",
                    text: "围绕企业专项申请做全面的风险预警。",
                    money: "",
                    category: ''
                },
                {
                    title: "专项侵权对抗",
                    text: "专项对其他企业发生侵权时提供对抗方案。",
                    money: "",
                    category: ''
                },
                {
                    title: "无效/异议提起",
                    text: "对其他企业的专项提起无效、异议申请。",
                    money: "",
                    category: ''
                },
                {
                    title: "知识产权维权",
                    text: "知识产权受到侵害时提供维权服务。",
                    money: "",
                    category: ''
                },
            ],
            serviceList: [{
                    patentTitle: "国家高新企业认定",
                    title: "国家高新企业认定",
                    imgurl: require('../../assets/cx-logo1.png'),
                    text: "享受税收减免优惠，获得政府政策扶持，推动企业快速发展。",
                    money: '',
                    category: ''
                },
                {
                    patentTitle: "双软认证",
                    title: "双软认证",
                    imgurl: require('../../assets/cx-logo2.png'),
                    text: "通过软件产品登记和软件企业认定给予企业税收减免等政策支持。",
                    money: '',
                    category: ''
                },
                {
                    patentTitle: "项目申报",
                    imgurl: require('../../assets/cx-logo3.png'),
                    title: "项目申报",
                    text: "企业项目，可申请政府企业扶持资金、贴息、免息贷款。",
                    money: '',
                    category: ''
                },
            ]
        }
    }
}

const state = defaultState()

const mutations = {
    SET_PRECE(state, data) {

        data.innovationList.filter(item => {
            state.innovationList.keyan.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.innovationList.filter(item => {
            state.innovationList.patentServiceList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.copyrightList.filter(item => {
            state.copyrightList.lawList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.copyrightList.filter(item => {
            state.copyrightList.serviceList.filter(items => {
                if (item.name === items.patentTitle) {
                    items.price = item.money
                    items.category = item.category
                }
            })
        })

        data.innovationList.filter(item => {
            state.innovationList.serviceList.filter(items => {
                if (item.name === items.patentTitle) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.trademarkList.filter(item => {
            state.trademark.renewList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.trademarkList.filter(item => {
            state.trademark.registerList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.trademarkList.filter(item => {
            state.trademark.maintainList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
        })

        data.trademarkList.filter(item => {
            state.trademark.serviceList.filter(items => {
                if (item.name === items.patentTitle) {
                    items.price = item.money
                    items.category = item.category
                }
            })
        })

        data.patentList.filter(item => {
            state.patentlist.lawList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
            state.patentlist.otherService.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })
            state.patentlist.copyrightList.filter(items => {
                if (item.name === items.title) {
                    items.money = item.money
                    items.category = item.category
                }
            })

            state.patentlist.serviceList.filter(items => {
                if (item.name === items.patentTitle) {
                    items.price = item.money
                    items.category = item.category
                }
            })
        })
    }
}

const actions = {
    async gPrice({
        commit
    }) {
        const res = await price()
        if (res.code === 200) {
            commit('SET_PRECE', res.data)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}