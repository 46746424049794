import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import home from './modules/home'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    IsLogin: false,
  },
  mutations: {},
  modules: {
      user,
      home
  }
});
export default store;